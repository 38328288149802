import React, { useEffect, useState } from "react";
import "./App.css";
import Tool from "./Tool";
import Privacy from "./Privacy";
import Faq from "./Faq";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ConfigProvider } from "antd";
import Termsandconditions from "./Termsandconditions";

function App() {
  const navigate = useNavigate();
  const [access, setAccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [paid, setPaid] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const pass = localStorage.getItem("ParPostsPass");
      const tempUser = localStorage.getItem("ParPostsPrevious");
      if (tempUser !== "true") {
        setAccess(true);
      }

      if (pass === process.env.REACT_APP_CUSTOMER_KEY) {
        setAccess(true);
        setPaid(true);
      }

      setLoading(false);
    }, 2000);
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#00b96b",
        },
      }}
    >
      <div className="App">
        <header>
          <div className="headerContent">
            <div
              className="topLeftLogo"
              onClick={() => {
                navigate("/");
              }}
            >
              <div className="leftPartOfLogo">PAR</div>
              <div className="rightPartOfLogo">POSTS</div>
            </div>
            <div className="topRightMenu">
              <button
                className="menuButton"
                onClick={() => {
                  navigate("/faq");
                }}
              >
                FAQ
              </button>
            </div>
          </div>
        </header>
        <Routes>
          <Route
            path="/"
            element={
              <Tool
                setAccess={setAccess}
                access={access}
                loading={loading}
                setLoading={setLoading}
              />
            }
          />
          <Route path="/privacypolicy" element={<Privacy />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/terms" element={<Termsandconditions />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        {!loading && <div className="paid">{paid ? "Paid " : "Free "}User</div>}

        <footer>
          <div className="footerContent">
            <button
              className="footerLink"
              onClick={() => {
                navigate("/privacypolicy");
              }}
            >
              Privacy Policy
            </button>
            <button
              className="footerLink"
              onClick={() => {
                navigate("/terms");
              }}
            >
              T&Cs
            </button>
            <div>© Copyright 2023 JaysWay LLC</div>
          </div>
        </footer>
      </div>
    </ConfigProvider>
  );
}

export default App;
