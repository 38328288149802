import React from 'react'

function Privacy() {
  return (
    <div className="privacyPage">
        <div className="privacyContent">
    <h2 className="privacy">Privacy Policy</h2>
    <h4>General information</h4>
    <p className="privacyPara">
      This privacy policy describes how JaysWay Oy (“JaysWay” or
      “we”) processes personal data; what kinds of personal data
      JaysWay collects, for which purposes the data is used, to
      which parties the data can be disclosed as well as the
      rights of the data subjects. The privacy policy also
      provides information on the obligations to be met by JaysWay
      regarding the processing of personal data. This privacy
      policy applies to all JaysWay services, websites,
      applications, marketing and customer communications
      (“services”). The privacy policy applies to all persons
      whose personal data is processed in connection with the
      services. In addition, the privacy policy applies to the
      processing of personal data by our service providers and
      other external partners. Personal data, data subject,
      controller and other key terms are defined in the General
      Data Protection Regulation (2016/679, “GDPR”). The company
      complies with the GDPR in all processing of personal data in
      conjunction with other applicable national data protection
      legislation (“data protection legislation”). JaysWay is
      committed to protecting the privacy of data subjects and to
      complying with the GDPR, applicable data protection laws and
      other applicable national laws. The website and services may
      also contain links to external websites and services
      operated by other organizations that we do not manage. This
      privacy policy is not applicable to their use, so we
      encourage you to review the privacy policies that apply to
      them. We are not responsible for the privacy policies of
      other websites or external services (even if you access them
      using the links on our website). We provide these links only
      as additional information to better serve you.
    </p>
    <h4>Controller</h4>
    <p className="privacyPara">JaysWay Oy</p>
    <p className="privacyPara">Business ID: 3263247-5</p>
    <p className="privacyPara">jayswayoy@gmail.com</p>
    <h4>Purposes and legal basis for processing personal data</h4>
    <p className="privacyPara">
      We only process personal data that is necessary for each
      purpose set out below. We process personal data in
      accordance with the applicable data protection legislation.
      Personal data will be processed for the following purposes:
    </p>
    <p className="privacyPara">
      <ul>
        <li>Customer service and communication</li>
        <li>Provision, design and development of the services</li>
        <li>Measuring customer satisfaction</li>
        <li>
          Marketing, including market research, other marketing
          promotion and analysis, and the production of statistics
          and the measurement of marketing effectiveness
        </li>
        <li>
          Improving the user experience of our websites and
          applications and tracking user traffic
        </li>
        <li>Handling inquiries related to services</li>
        <li>Administrative purposes</li>
        <li>
          Processing customer order information and monitoring
          payment status
        </li>
        <li>
          Management of legal obligations (eg accounting and other
          legislation) and reporting obligations, such as
          reporting related to tax legislation
        </li>
        <li>Prevention of abuse</li>
      </ul>
    </p>
    <p className="privacyPara">
      The legal basis for the processing of personal data is
      mainly the implementation or preparation of an agreement
      between the data subject and JaysWay and the legitimate
      interests of JaysWay. Our legitimate interests include, for
      example, managing and developing the services and handling
      inquiries related to our services, as well as other customer
      service and customer communications, as well as marketing
      and monitoring the effectiveness of marketing activities and
      user traffic to our website and application. When we process
      personal data based on a legitimate interest, we have
      carefully balanced such legitimate interest with the data
      subjects right to privacy and concluded that our interest
      outweighs the data subjects’ rights and freedoms.
    </p>
    <h4>What data is collected, stored and processed?</h4>
    <p className="privacyPara">
      Personal data refers to any information relating to a
      natural person (“data subject”) that can identify him/ her
      directly or indirectly, as set out in the GDPR. Information
      from which the data subject cannot be directly or indirectly
      identified is not personal data. The company collects only
      such personal data from the data subject that is relevant
      and necessary for the purposes described in this privacy
      policy. Personal data is mainly collected from the user at
      the time of registration or later during the customer
      relationship, including via logging in to the services,
      using the services and visiting the website. The following
      personal data from the data subjects will be processed:
    </p>
    <p className="privacyPara">
      <ul>
        <li>
          contact information such as an e-mail address as well as
          other registration information such as name, username,
          nickname and any other unique identifier
        </li>
        <li>profile description and picture</li>
        <li>
          billing and payment information as well as other related
          information
        </li>
        <li>
          information related to orders and feedback, chat
          messages and payments to be made
        </li>
        <li>
          registration, login and password recovery information,
          ip address, electronic communications identification
          information, information related to the service of the
          logged-in user, such as information on the use and
          browsing of service features, information collected
          using cookies and other similar technologies, and
          browser as well as operating system information.
        </li>
        <li>
          location information, such as coordinates calculated
          using GPS or WLAN access points or or coordinates
          calculated using base stations in the cellular network,
          this information is only gathered if explicitly
          authorized by the user.
        </li>
        <li>
          information on the marketing content used by the data
          subject, such as message opening and information
          regarding clicks
        </li>
        <li>
          Event information, such as the registrant’s
          participation in events.
        </li>
      </ul>
    </p>
    <p className="privacyPara">
      Personal data may be updated and supplemented by collecting
      data from private and public sources.
    </p>
    <h4>Retention of personal data</h4>
    <p className="privacyPara">
      JaysWay will retain user data only for as long as it is
      necessary to fulfill the purpose for which it was collected
      in accordance with this privacy policy, unless JaysWay is
      required by law to retain the personal data for a longer
      period (e.g., accounting or reporting obligations), or
      unless the personal data must be retained due to legal
      proceeding or similar dispute resolution purposes. JaysWay
      will delete your personal data within a reasonable time or
      make it anonymous. The necessity of storing personal data,
      as well as the accuracy of the data, is regularly reviewed.
      Customer data and other material related to customer
      agreements are retained due to statutory and contractual
      obligations and responsibilities mainly for a period of 10
      years from the end of the tax year or accounting period.
      Upon request, we will provide more information regarding the
      retention periods.
    </p>
    <h4> Transfers and recipients of personal data</h4>
    <p className="privacyPara">
      {" "}
      JaysWay processes personal data in accordance with
      applicable law. JaysWay will not disclose personal data to
      third parties outside the JaysWay organization unless there
      is a justified reason, legitimate interest, or express
      consent. Personal data may be disclosed to third parties for
      processing in accordance with this privacy policy in order
      to ensure the functioning of the service and customer
      relationships, for example in connection with order and
      payment of goods, regulatory obligations, marketing and
      customer communications. The personal data may also be
      disclosed to third parties if required under any applicable
      law or regulation or order by competent authorities, and to
      investigate possible infringing use of the services as well
      as to ensure the safety of the services. In addition,
      personal data may have to be disclosed in connection with
      legal proceedings or similar dispute resolution purposes.
      Personal data may be disclosed to third-party service
      providers in accordance with this privacy policy. JaysWay
      may use a variety of service providers and other partners to
      process data, such as IT-, accounting and financial service
      providers, in which case JaysWay ensures the lawful
      processing of data through contractual arrangements and by
      instructing third parties on the processing of data. The
      third parties may change. These third parties process
      information only on behalf of and for the benefit of
      JaysWay. We will provide more information regarding the
      processing and third parties upon request. Some of the
      services used by JaysWay for processing personal data
      operate outside the territory of the European Union or the
      European Economic Area. Thus, Personal data can be
      transferred regularly outside the European Union (EU) and
      the European Economic Area (EEA). In case personal data is
      transferred outside EU/EEA, such transfers are either made
      to a country that is deemed to provide a sufficient level of
      privacy protection by the European Commission or transfers
      are carried out by using appropriate safeguards such as
      standard data protection clauses adopted or otherwise
      approved by the EU Commission. We will provide more
      information regarding the processing upon request.
    </p>
    <h4> How is the data protected?</h4>
    <p className="privacyPara">
      We use appropriate technical, administrative and
      organizational security measures to protect personal data
      against unauthorized access, disclosure, destruction or
      other unauthorized processing. Firewalls, secure equipment
      rooms, proper access control, controlled provision of access
      and monitoring of use, and the use of encryption
      technologies are also in use. The servers are located in the
      EU. Network services are protected by a HTTPS connection,
      which encrypts communications. All parties processing
      personal data have a duty of confidentiality in matters
      related to the processing of personal data. Access to
      personal data is restricted to those employees who need it
      to perform their duties. Employees and other processors of
      personal data have personal usernames and passwords. We also
      require our service providers to have appropriate methods in
      place to protect personal data. Nevertheless, considering
      the cyber threats in modern day online environment, we
      cannot give full guarantee that our security measures will
      prevent illegally and maliciously operating third parties
      from obtaining access to personal data or absolute security
      of the personal data during its transmission or storage on
      our systems.
    </p>
    <h4>Cookies</h4>
    <p className="privacyPara">
      JaysWay may collect information about a user’s terminal
      using cookies and other similar technologies, such as the
      browser’s local data warehouse. A cookie is a small text
      file that a browser stores on a user’s terminal. Cookies
      often contain an anonymous, unique identifier that can be
      used to identify and count browsers visiting the site.
    </p>
    <h4>USE OF LOCATION DATA</h4>
    <p className="privacyPara">
      The location information of the user’s terminal may be used
      to provide location-based services, such as locating a
      nearby course, provided that the user has given their
      explicit consent to the processing of the location
      information or said information has been anonymized.
      Location data is determined using available location
      methods, such as GPS and WLAN access points and cellular
      base station locations. The user can withdraw his consent at
      any time. Location data is processed and stored only for the
      time required to provide the services which are based on the
      location data.
    </p>
    <h4>Rights of data subjects</h4>
    <h5>Right of access and right of inspection</h5>
    <p className="privacyPara">
      The data subject has the right to obtain confirmation as to
      whether or not and what personal data concerning him or her
      is being processed and receive a copy of said information.
      The data subject should send the request to
      jayswayoy@gmail.com. The data subject must provide in the
      request the details necessary to search for the information.
      If necessary, the controller shall ask for additional
      information to identify the data subject. The data subject
      has the right to access the personal data and receive a copy
      of the personal data. The response to the request for
      verification will be sent to the person requesting the
      information by e-mail, unless the data subject requests
      otherwise.
    </p>
    <h5> Right to rectification</h5>
    <p className="privacyPara">
      JaysWay takes care of the quality of the personal data it
      processes to the best of its ability. JaysWay may correct,
      delete or supplement incorrect, inaccurate or unnecessary
      personal data on its own initiative or at the request of the
      data subject.
    </p>
    <p className="privacyPara">
      The data subject has the right to request the deletion of
      his or her personal data in accordance with the provisions
      of applicable data protection legislation. Please note that
      if personal data is needed, for example, to comply with a
      legal obligation, to resolve a legal dispute or to achieve
      the purposes defined in this privacy policy, a request for
      deletion cannot, in principle, be fulfilled.
    </p>
    <h5> Right to restriction of processing</h5>
    <p className="privacyPara">
      The data subject has the right, under conditions defined by
      data protection legislation, to request the restriction of
      processing of his/ her personal data. In situations where
      personal data suspected to be incorrect cannot be corrected
      or removed, or if the removal request is unclear, the
      company will limit the access to such data.
    </p>
    <h5> Right to object to processing</h5>
    <p className="privacyPara">
      The data subject has the right to object to certain
      processing of data, under conditions defined by data
      protection legislation, for example, the data subject may
      object to his/her personal data being used for profiling.
      The data subject also has the right to object to the
      processing of his or her personal data for direct marketing
      purposes.
    </p>
    <h5> Right to data portability</h5>
    <p className="privacyPara">
      The data subject has the right to receive the personal data
      that he or she has provided in a structured, commonly used
      and machine-readable format and, if desired, transmit that
      data to another controller.
    </p>
    <h5> Right to withdraw consent</h5>
    <p className="privacyPara">
      If the processing of personal data is based on the data
      subject’s consent, the data subject has the right to
      withdraw his or her consent at any time. Withdrawal of
      consent shall not affect any previous processing. In
      addition, the data subject may object to direct marketing
      and may withdraw his or her consent to electronic direct
      marketing by following the instructions included in the
      marketing communications (e.g., unsubscribing by way of a
      feature attached to an email or text message).
    </p>
    <h5>
      {" "}
      Right to lodge a complaint with a supervisory authority
    </h5>
    <p className="privacyPara">
      The data subject has the right to lodge a complaint with a
      competent data protection authority if the data subject
      considers that the processing of personal data relating to
      him or her infringes current legislation. Contact
      information for the Finnish Data Protection Authority can be
      found{" "}
      <span>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://tietosuoja.fi/etusivu"}
        >
          here
        </a>
      </span>
      .
    </p>{" "}
    <h5> Exercising rights</h5>
    <p className="privacyPara">
      For questions about the privacy policy and user rights,
      please email jayswayoy@gmail.com. We may request additional
      information as necessary in order to fulfill the above
      requests. We will process your request upon receipt of all
      necessary information related to your request (including
      verification of identity). The request shall be answered
      within a reasonable time and, if possible, no later than one
      month after the request has been submitted and the identity
      verified. If the request cannot be granted, the refusal
      shall be notified separately. We may refuse to process
      requests that are disproportionately repetitive,
      unreasonable, or manifestly unfounded.
    </p>
    <h4>Changes to the privacy policy</h4>
    <p className="privacyPara">
      JaysWay may make changes to this privacy policy at any time
      by giving a notice on the website and/or by other applicable
      means. The data subjects are highly recommended to review
      the privacy policy on our website every now and then. If the
      data subject objects to any of the changes to this privacy
      policy, the data subject should cease using the services,
      where applicable, and he/she can request that we remove the
      personal data, unless applicable laws require us to retain
      such personal data. Unless stated otherwise, the
      then-current privacy policy applies to all personal data we
      process at the time.
    </p>
    <br />
    <p className="privacyPara">
      This privacy policy has been published on 04.12.2023–
      Version 2023-01
    </p>
    </div>
  </div>
  )
}

export default Privacy