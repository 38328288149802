import "./App.css";
import { useState, useMemo, useEffect, useRef } from "react";
import Cropper from "react-easy-crop";
import CroppedBg from "./components/CroppedBg";
import {
  Radio,
  ColorPicker,
  Slider,
  InputNumber,
  Input,
  Table,
  Divider,
  Button,
  Tooltip,
  Spin,
  Modal,
  Carousel,
} from "antd";
import {
  LeftOutlined,
  RightOutlined,
  DownloadOutlined,
  ReloadOutlined,
  LoadingOutlined,
  KeyOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";
import carousel1 from "./carousel1.png";
import carousel2 from "./carousel2.png";
import carousel3 from "./carousel3.png";

const columns = [
  {
    title: "Hole",
    dataIndex: "hole",
    width: "34%",
    editable: false,
  },
  {
    title: "Par",
    dataIndex: "par",
    width: "33%",
    editable: true,
  },
  {
    title: "Score",
    dataIndex: "num",
    width: "33%",
    editable: true,
  },
];

function Tool({ setAccess, access, loading, setLoading }) {
  const divRef = useRef(null);
  const [customerKey, setCustomerKey] = useState("");
  const [wrongKey, setWrongKey] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isSuperMobile, setIsSuperMobile] = useState(false);
  const [firstName, setFirstName] = useState("PLAYER");
  const [secondName, setSecondName] = useState("NAME");
  const [courseName, setCourseName] = useState("Tournament at This Links");
  const [scoreText, setScoreText] = useState("Season Best");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [stage, setStage] = useState(0);
  const [croppedArea, setCroppedArea] = useState({
    x: 0,
    y: 0,
    height: 100,
    width: 100,
  });
  const [gradientLocation, setGradientLocation] = useState("180");
  const [isOpen, setIsOpen] = useState(false);
  const [scoreLocation, setScoreLocation] = useState("right");
  const [colorRgb, setColorRgb] = useState("rgb(0,0,0)");
  const [sliderValues, setSliderValues] = useState([20, 40]);
  const [secondGradient, setSecondGradient] = useState(70);
  const [yourScores, setYourScores] = useState([
    4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4,
  ]);
  const [yourPars, setYourPars] = useState([
    4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4,
  ]);
  const navigate = useNavigate();

  const handleDownload = () => {
    setIsOpen(false);
    let scale = isSuperMobile ? 5 : 3;

    if (divRef.current) {
      divRef.current.style.borderRadius = "0px";
      html2canvas(divRef.current, { scale: scale }).then((canvas) => {
        divRef.current.style.borderRadius = "0px";
        const link = document.createElement("a");
        if (isSuperMobile) {
          divRef.current.style.borderRadius = "8px";
        } else {
          divRef.current.style.borderRadius = "10px";
        }
        link.href = canvas.toDataURL("image/png");
        link.download = "MyParPostsScoreCard.png";
        document.body.appendChild(link);
        link.click();
        localStorage.setItem("ParPostsPrevious", "true");

        const pass = localStorage.getItem("ParPostsPass");

        if (pass !== process.env.REACT_APP_CUSTOMER_KEY) {
          setLoading(true);
          setTimeout(() => {
            setAccess(false);
            setLoading(false);
          }, 2000);
        }

        document.body.removeChild(link);
      });
    }
  };

  const handleResize = () => {
    if (window.innerWidth < 1000) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    if (window.innerWidth < 612) {
      setIsSuperMobile(true);
    } else {
      setIsSuperMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    handleResize();
  }, []);

  const tableDataFront = [
    {
      num: (
        <InputNumber
          defaultValue={4}
          size="small"
          style={{ width: "60px" }}
          max={9}
          min={1}
          value={yourScores[0]}
          onChange={(e) => changeScore(0, e)}
        />
      ),
      par: (
        <InputNumber
          defaultValue={4}
          size="small"
          style={{ width: "60px" }}
          max={5}
          min={3}
          value={yourPars[0]}
          onChange={(e) => changePar(0, e)}
        />
      ),
      hole: 1,
    },
    {
      num: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={9}
          min={1}
          value={yourScores[1]}
          onChange={(e) => changeScore(1, e)}
        />
      ),
      par: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={5}
          min={3}
          value={yourPars[1]}
          onChange={(e) => changePar(1, e)}
        />
      ),
      hole: 2,
    },
    {
      num: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={9}
          min={1}
          value={yourScores[2]}
          onChange={(e) => changeScore(2, e)}
        />
      ),
      par: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={5}
          min={3}
          value={yourPars[2]}
          onChange={(e) => changePar(2, e)}
        />
      ),
      hole: 3,
    },
    {
      num: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={9}
          min={1}
          value={yourScores[3]}
          onChange={(e) => changeScore(3, e)}
        />
      ),
      par: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={5}
          min={3}
          value={yourPars[3]}
          onChange={(e) => changePar(3, e)}
        />
      ),
      hole: 4,
    },
    {
      num: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={9}
          min={1}
          value={yourScores[4]}
          onChange={(e) => changeScore(4, e)}
        />
      ),
      par: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={5}
          min={3}
          value={yourPars[4]}
          onChange={(e) => changePar(4, e)}
        />
      ),
      hole: 5,
    },
    {
      num: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={9}
          min={1}
          value={yourScores[5]}
          onChange={(e) => changeScore(5, e)}
        />
      ),
      par: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={5}
          min={3}
          value={yourPars[5]}
          onChange={(e) => changePar(5, e)}
        />
      ),
      hole: 6,
    },
    {
      num: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={9}
          min={1}
          value={yourScores[6]}
          onChange={(e) => changeScore(6, e)}
        />
      ),
      par: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={5}
          min={3}
          value={yourPars[6]}
          onChange={(e) => changePar(6, e)}
        />
      ),
      hole: 7,
    },
    {
      num: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={9}
          min={1}
          value={yourScores[7]}
          onChange={(e) => changeScore(7, e)}
        />
      ),
      par: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={5}
          min={3}
          value={yourPars[7]}
          onChange={(e) => changePar(7, e)}
        />
      ),
      hole: 8,
    },
    {
      num: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={9}
          min={1}
          value={yourScores[8]}
          onChange={(e) => changeScore(8, e)}
        />
      ),
      par: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={5}
          min={3}
          value={yourPars[8]}
          onChange={(e) => changePar(8, e)}
        />
      ),
      hole: 9,
    },
  ];

  const tableDataBack = [
    {
      num: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={9}
          min={1}
          value={yourScores[9]}
          onChange={(e) => changeScore(9, e)}
        />
      ),
      par: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={5}
          min={3}
          value={yourPars[9]}
          onChange={(e) => changePar(9, e)}
        />
      ),
      hole: 10,
    },
    {
      num: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={9}
          min={1}
          value={yourScores[10]}
          onChange={(e) => changeScore(10, e)}
        />
      ),
      par: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={5}
          min={3}
          value={yourPars[10]}
          onChange={(e) => changePar(10, e)}
        />
      ),
      hole: 11,
    },
    {
      num: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={9}
          min={1}
          value={yourScores[11]}
          onChange={(e) => changeScore(11, e)}
        />
      ),
      par: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={5}
          min={3}
          value={yourPars[11]}
          onChange={(e) => changePar(11, e)}
        />
      ),
      hole: 12,
    },
    {
      num: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={9}
          min={1}
          value={yourScores[12]}
          onChange={(e) => changeScore(12, e)}
        />
      ),
      par: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={5}
          min={3}
          value={yourPars[12]}
          onChange={(e) => changePar(12, e)}
        />
      ),
      hole: 13,
    },
    {
      num: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={9}
          min={1}
          value={yourScores[13]}
          onChange={(e) => changeScore(13, e)}
        />
      ),
      par: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={5}
          min={3}
          value={yourPars[13]}
          onChange={(e) => changePar(13, e)}
        />
      ),
      hole: 14,
    },
    {
      num: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={9}
          min={1}
          value={yourScores[14]}
          onChange={(e) => changeScore(14, e)}
        />
      ),
      par: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={5}
          min={3}
          value={yourPars[14]}
          onChange={(e) => changePar(14, e)}
        />
      ),
      hole: 15,
    },
    {
      num: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={9}
          min={1}
          value={yourScores[15]}
          onChange={(e) => changeScore(15, e)}
        />
      ),
      par: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={5}
          min={3}
          value={yourPars[15]}
          onChange={(e) => changePar(15, e)}
        />
      ),
      hole: 16,
    },
    {
      num: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={9}
          min={1}
          value={yourScores[16]}
          onChange={(e) => changeScore(16, e)}
        />
      ),
      par: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={5}
          min={3}
          value={yourPars[16]}
          onChange={(e) => changePar(16, e)}
        />
      ),
      hole: 17,
    },
    {
      num: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={9}
          min={1}
          value={yourScores[17]}
          onChange={(e) => changeScore(17, e)}
        />
      ),
      par: (
        <InputNumber
          size="small"
          style={{ width: "60px" }}
          max={5}
          min={3}
          value={yourPars[17]}
          onChange={(e) => changePar(17, e)}
        />
      ),
      hole: 18,
    },
  ];

  function changeScore(i, e) {
    let cur = [...yourScores];
    cur[i] = e;
    setYourScores(cur);
  }

  function changePar(i, e) {
    let cur = [...yourPars];
    cur[i] = e;
    setYourPars(cur);
  }
  const changeGradientLocation = (e) => {
    setGradientLocation(e.target.value);
  };

  const changeScoreLocation = (e) => {
    setGradientLocation("180");
    setScoreLocation(e.target.value);
  };

  const changeSecondGradient = (e) => {
    setSecondGradient(e);
  };

  const rgbString = useMemo(
    () => (typeof colorRgb === "string" ? colorRgb : colorRgb.toRgbString()),
    [colorRgb]
  );

  function checkForPass() {
    const pass = localStorage.getItem("ParPostsPass");

    if (pass === process.env.REACT_APP_CUSTOMER_KEY) {
      handleDownload();
    } else {
      setIsOpen(true);
    }
  }

  function submitKey(key) {
    if (key === process.env.REACT_APP_CUSTOMER_KEY) {
      localStorage.setItem("ParPostsPass", key);
      window.location.reload();
    } else {
      setWrongKey(true);
    }
  }

  return (
    <>
      {loading && (
        <div className="mainPage">
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: "50px",
                  color: "#668561",
                }}
                spin
              />
            }
          />
        </div>
      )}
      {!loading && (
        <div className="mainPage">
          {!selectedImage && access && (
            <div className="homeFlex" style={{ alignItems: "flex-start" }}>
              <div
                className="purchasePage"
                style={isMobile ? { width: "80%" } : {}}
              >
                <h1
                  style={{
                    fontWeight: "900",
                  }}
                >
                  Hey There 👋
                </h1>
                <h3>Welcome to Par Posts!</h3>
                <p>
                  Get started by uploading your favorite golf photo below 📷🏌️‍♂️
                </p>
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="select-image"
                  accept="image/png, image/jpeg"
                  name="myBackground"
                  onChange={(event) => {
                    setSelectedImage(event.target.files[0]);
                  }}
                />

                <div
                  className="inputField"
                  style={
                    isMobile
                      ? {
                          width: "100%",
                        }
                      : {}
                  }
                >
                  <UploadOutlined
                    style={{ color: "#668561", fontSize: "50px" }}
                  />
                  <Button
                    onClick={() => {
                      document.getElementById("select-image").click();
                    }}
                    type="primary"
                  >
                    Click To Upload
                  </Button>
                  <p
                    style={{
                      paddingTop: "0px",
                      marginTop: "10px",
                      fontSize: "10px",
                      color: "gray",
                    }}
                  >
                    (Supports PNG and JPEG files)
                  </p>
                </div>
              </div>
              <div
                className={
                  isMobile ? "carouselContainerMobile" : "carouselContainer"
                }
              >
                <Carousel autoplay>
                  <img
                    className="sampleImage"
                    src={carousel1}
                    alt="first sample"
                  />
                  <img
                    className="sampleImage"
                    src={carousel2}
                    alt="second sample"
                  />
                  <img
                    className="sampleImage"
                    src={carousel3}
                    alt="third sample"
                  />
                </Carousel>
                <div className="carouselDisclaimer">
                  *The names associated with the images are randomly generated
                  and for illustrative purposes only; they do not correspond to
                  real individuals.
                </div>
              </div>
            </div>
          )}
          {!access && (
            <div
              className="purchasePage"
              style={isMobile ? { width: "80%" } : {}}
            >
              <h1
                style={{
                  fontWeight: "900",
                  paddingBottom: "0px",
                }}
              >
                Congratulations
              </h1>
              <p>You've created your first Par Post 🏌️‍♂️</p>
              <p style={{ textAlign: "left", maxWidth: "90vw" }}>
                For unlimited downloads check out our Paid Plan for only{" "}
                <strong> €2.00 </strong> (
                <span style={{ textDecoration: "line-through" }}>€4.00</span>{" "}
                50% OFF with code <strong>LAUNCH</strong>)
              </p>
              <Button type="primary" style={{ padding: "0px" }}>
                <a
                  style={{
                    all: "unset",
                    color: "white",
                    width: "100%",
                    height: "100%",
                    padding: "5px 10px",
                  }}
                  rel="noreferrer"
                  href={process.env.REACT_APP_STRIPE_LINK}
                >
                  Purchase Plan
                </a>
              </Button>
              <p style={{ fontSize: "9px" }}>
                *By purchasing you accept ParPosts{" "}
                <button
                  className="footerLink"
                  onClick={() => {
                    navigate("/terms");
                  }}
                >
                  T&Cs
                </button>
              </p>

              <h2 style={{ marginTop: "100px" }}>Already purchased a plan?</h2>
              <p>
                Input the code you received in the receipt attached to your
                payment confirmations e-mail
              </p>
              <Input
                status={wrongKey ? "error" : ""}
                size="large"
                value={customerKey}
                onChange={(e) => {
                  setCustomerKey(e.target.value);
                  setWrongKey(false);
                }}
                placeholder="Customer Key"
                prefix={<KeyOutlined />}
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  marginBottom: "10px",
                }}
              />
              <Button type="default" onClick={() => submitKey(customerKey)}>
                Submit
              </Button>
              {wrongKey && <div className="errorInKey">Invalid Key</div>}
            </div>
          )}

          {croppedArea && selectedImage && access && (
            <>
              <Modal
                open={isOpen}
                onCancel={() => {
                  setIsOpen(false);
                }}
                cancelText="Keep Editing"
                okText="Download"
                title="Download Your Par Post"
                onOk={() => {
                  handleDownload();
                }}
              >
                <p>Are you sure you wish to download this image?</p>
                <p style={{ fontSize: "10px", marginTop: "20px" }}>
                  *Free users only receive one download
                </p>
              </Modal>
              <div className="editPage">
                {!isMobile && (
                  <>
                    <div className="topNotes">
                      {stage === 1 && (
                        <>
                          <h3 style={{ marginTop: "5px" }}>Score Location</h3>
                          <Radio.Group
                            onChange={changeScoreLocation}
                            value={scoreLocation}
                            buttonStyle="solid"
                            style={{ width: "300px", textAlign: "center" }}
                          >
                            <Radio.Button style={{ width: "50%" }} value="left">
                              Left
                            </Radio.Button>
                            <Radio.Button
                              style={{ width: "50%" }}
                              value="right"
                            >
                              Right
                            </Radio.Button>
                          </Radio.Group>
                          <h3>Gradient Location</h3>
                          <Radio.Group
                            onChange={changeGradientLocation}
                            value={gradientLocation}
                            buttonStyle="solid"
                            style={{ width: "300px", textAlign: "center" }}
                          >
                            <Radio.Button style={{ width: "33%" }} value="270">
                              Left
                            </Radio.Button>
                            <Radio.Button style={{ width: "34%" }} value="180">
                              Bottom
                            </Radio.Button>
                            <Radio.Button style={{ width: "33%" }} value="90">
                              Right
                            </Radio.Button>
                          </Radio.Group>
                          <h3>Gradient Color</h3>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <ColorPicker
                              value={colorRgb}
                              onChange={setColorRgb}
                              disabledAlpha
                            />
                            RGB: <span>{rgbString}</span>
                          </div>
                          <h3>Gradient Widths</h3>
                          <Slider
                            range
                            defaultValue={sliderValues}
                            onChange={setSliderValues}
                            style={{ width: "300px", maxWidth: "100%" }}
                            max={100}
                            min={0}
                          />
                          <h3>Gradient Strength</h3>

                          <InputNumber
                            min={0}
                            max={100}
                            defaultValue={secondGradient}
                            onChange={changeSecondGradient}
                          />
                        </>
                      )}
                      {stage === 0 && (
                        <>
                          <h3 style={{ marginTop: "5px" }}>Crop</h3>
                          <div className="postContainer">
                            <Cropper
                              style={{ borderRadius: "10px" }}
                              image={URL.createObjectURL(selectedImage)}
                              crop={crop}
                              zoom={zoom}
                              aspect={1}
                              onCropChange={setCrop}
                              onZoomChange={setZoom}
                              onCropAreaChange={setCroppedArea}
                            />
                          </div>
                        </>
                      )}
                      {stage === 2 && (
                        <>
                          <h3 style={{ marginTop: "5px" }}>First Name</h3>
                          <Input
                            value={firstName}
                            onChange={(e) =>
                              setFirstName(e.target.value.toUpperCase())
                            }
                            size="large"
                          />
                          <h3>Second Name</h3>
                          <Input
                            value={secondName}
                            onChange={(e) =>
                              setSecondName(e.target.value.toUpperCase())
                            }
                            size="large"
                          />
                          <h3>Subtitle</h3>
                          <Input
                            value={courseName}
                            onChange={(e) => setCourseName(e.target.value)}
                            size="large"
                          />
                          <h3>Score Text</h3>
                          <Input
                            value={scoreText}
                            onChange={(e) => setScoreText(e.target.value)}
                            size="large"
                          />
                        </>
                      )}
                      {stage === 3 && (
                        <>
                          <h3 style={{ marginTop: "5px" }}>
                            Front Nine Scores
                          </h3>
                          <Table
                            style={{ width: "300px" }}
                            size="small"
                            bordered
                            dataSource={tableDataFront}
                            columns={columns}
                            pagination={false}
                          />
                        </>
                      )}
                      {stage === 4 && (
                        <>
                          <h3 style={{ marginTop: "5px" }}>Back Nine Scores</h3>
                          <Table
                            style={{ width: "300px" }}
                            size="small"
                            bordered
                            dataSource={tableDataBack}
                            columns={columns}
                            pagination={false}
                          />
                        </>
                      )}
                      {stage === 5 && (
                        <>
                          <h3 style={{ marginTop: "5px" }}>
                            Download Your Post
                          </h3>

                          <div className="downloadContainer">
                            <Button
                              onClick={() => checkForPass()}
                              style={{ width: "300px" }}
                              type="primary"
                              icon={<DownloadOutlined />}
                              size="large"
                            >
                              Download
                            </Button>
                          </div>
                        </>
                      )}
                      <div className="backAndNext">
                        <div
                          className="actualNavButtons"
                          style={{ marginTop: "10px" }}
                        >
                          <div
                            style={
                              stage === 0
                                ? { backgroundColor: "rgb(63, 63, 63)" }
                                : {}
                            }
                            className="navLocationCircle"
                          />
                          <div
                            style={
                              stage === 1
                                ? { backgroundColor: "rgb(63, 63, 63)" }
                                : {}
                            }
                            className="navLocationCircle"
                          />
                          <div
                            style={
                              stage === 2
                                ? { backgroundColor: "rgb(63, 63, 63)" }
                                : {}
                            }
                            className="navLocationCircle"
                          />
                          <div
                            style={
                              stage === 3
                                ? { backgroundColor: "rgb(63, 63, 63)" }
                                : {}
                            }
                            className="navLocationCircle"
                          />
                          <div
                            style={
                              stage === 4
                                ? { backgroundColor: "rgb(63, 63, 63)" }
                                : {}
                            }
                            className="navLocationCircle"
                          />
                          <div
                            style={
                              stage === 5
                                ? { backgroundColor: "rgb(63, 63, 63)" }
                                : {}
                            }
                            className="navLocationCircle"
                          />
                          {stage !== 0 && (
                            <button
                              className="backButtonMobile"
                              onClick={() => {
                                let cur = stage - 1;
                                setStage(cur);
                              }}
                            >
                              <LeftOutlined
                                style={{ fontSize: "20px", color: "gray" }}
                              />
                            </button>
                          )}
                          {stage !== 5 && (
                            <button
                              className="nextButtonMobile"
                              onClick={() => {
                                let cur = stage + 1;
                                setStage(cur);
                              }}
                            >
                              <RightOutlined
                                style={{ fontSize: "20px", color: "gray" }}
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div
                  className="viewer"
                  style={isMobile ? { marginTop: "30px" } : {}}
                >
                  <CroppedBg
                    croppedArea={croppedArea}
                    selectedImage={selectedImage}
                    gradientLocation={gradientLocation}
                    colorRgb={colorRgb}
                    sliderValues={sliderValues}
                    secondGradient={secondGradient}
                    stage={stage}
                    yourScores={yourScores}
                    yourPars={yourPars}
                    firstName={firstName}
                    secondName={secondName}
                    courseName={courseName}
                    scoreText={scoreText}
                    isSuperMobile={isSuperMobile}
                    scoreLocation={scoreLocation}
                    divRef={divRef}
                  />
                  <Tooltip title="Use Different Image" placement="left">
                    <button
                      className="restartButton"
                      onClick={() => {
                        setSelectedImage(null);
                        setStage(0);
                      }}
                    >
                      <ReloadOutlined />
                    </button>
                  </Tooltip>
                </div>
              </div>
              {isMobile && (
                <>
                  <div
                    className="backAndNext"
                    style={{ marginTop: "20px", flexGrow: "unset" }}
                  >
                    <div className="actualNavButtons">
                      <div
                        style={
                          stage === 0
                            ? { backgroundColor: "rgb(63, 63, 63)" }
                            : {}
                        }
                        className="navLocationCircle"
                      />
                      <div
                        style={
                          stage === 1
                            ? { backgroundColor: "rgb(63, 63, 63)" }
                            : {}
                        }
                        className="navLocationCircle"
                      />
                      <div
                        style={
                          stage === 2
                            ? { backgroundColor: "rgb(63, 63, 63)" }
                            : {}
                        }
                        className="navLocationCircle"
                      />
                      <div
                        style={
                          stage === 3
                            ? { backgroundColor: "rgb(63, 63, 63)" }
                            : {}
                        }
                        className="navLocationCircle"
                      />
                      <div
                        style={
                          stage === 4
                            ? { backgroundColor: "rgb(63, 63, 63)" }
                            : {}
                        }
                        className="navLocationCircle"
                      />
                      <div
                        style={
                          stage === 5
                            ? { backgroundColor: "rgb(63, 63, 63)" }
                            : {}
                        }
                        className="navLocationCircle"
                      />
                      {stage !== 0 && (
                        <button
                          className="backButtonMobile"
                          onClick={() => {
                            let cur = stage - 1;
                            setStage(cur);
                          }}
                        >
                          <LeftOutlined
                            style={{ fontSize: "20px", color: "gray" }}
                          />
                        </button>
                      )}
                      {stage !== 5 && (
                        <button
                          className="nextButtonMobile"
                          onClick={() => {
                            let cur = stage + 1;
                            setStage(cur);
                          }}
                        >
                          <RightOutlined
                            style={{ fontSize: "20px", color: "gray" }}
                          />
                        </button>
                      )}
                    </div>
                  </div>

                  <Divider>Customizations</Divider>
                  <div
                    className="topNotes"
                    style={{
                      minHeight: "10px",
                      paddingBottom: "50px",
                      flexGrow: "1",
                    }}
                  >
                    {stage === 1 && (
                      <>
                        <h4 style={{ marginTop: "5px" }}>Score Location</h4>
                        <Radio.Group
                          onChange={changeScoreLocation}
                          value={scoreLocation}
                          buttonStyle="solid"
                          style={{ width: "300px", textAlign: "center" }}
                        >
                          <Radio.Button style={{ width: "50%" }} value="left">
                            Left
                          </Radio.Button>
                          <Radio.Button style={{ width: "50%" }} value="right">
                            Right
                          </Radio.Button>
                        </Radio.Group>
                        <h4>Gradient Location</h4>
                        <Radio.Group
                          onChange={changeGradientLocation}
                          value={gradientLocation}
                          buttonStyle="solid"
                          style={{ width: "300px", textAlign: "center" }}
                        >
                          <Radio.Button style={{ width: "33%" }} value="270">
                            Left
                          </Radio.Button>
                          <Radio.Button style={{ width: "34%" }} value="180">
                            Bottom
                          </Radio.Button>
                          <Radio.Button style={{ width: "33%" }} value="90">
                            Right
                          </Radio.Button>
                        </Radio.Group>
                        <h4>Gradient Color</h4>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <ColorPicker
                            value={colorRgb}
                            onChange={setColorRgb}
                            disabledAlpha
                          />
                          RGB: <span>{rgbString}</span>
                        </div>
                        <h4>Gradient Widths</h4>
                        <Slider
                          range
                          defaultValue={sliderValues}
                          onChange={setSliderValues}
                          style={{ width: "300px", maxWidth: "100%" }}
                          max={100}
                          min={0}
                        />
                        <h4>Gradient Strength</h4>

                        <InputNumber
                          min={0}
                          max={100}
                          defaultValue={secondGradient}
                          onChange={changeSecondGradient}
                        />
                      </>
                    )}
                    {stage === 0 && (
                      <>
                        <h4 style={{ marginTop: "5px" }}>Crop</h4>
                        <div className="postContainer">
                          <Cropper
                            style={{ borderRadius: "10px" }}
                            image={URL.createObjectURL(selectedImage)}
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropAreaChange={setCroppedArea}
                          />
                        </div>
                      </>
                    )}
                    {stage === 2 && (
                      <>
                        <h4 style={{ marginTop: "5px" }}>First Name</h4>
                        <Input
                          value={firstName}
                          onChange={(e) =>
                            setFirstName(e.target.value.toUpperCase())
                          }
                          size="large"
                        />
                        <h4>Second Name</h4>
                        <Input
                          value={secondName}
                          onChange={(e) =>
                            setSecondName(e.target.value.toUpperCase())
                          }
                          size="large"
                        />
                        <h4>Subtitle</h4>
                        <Input
                          value={courseName}
                          onChange={(e) => setCourseName(e.target.value)}
                          size="large"
                        />
                        <h4>Score Text</h4>
                        <Input
                          value={scoreText}
                          onChange={(e) => setScoreText(e.target.value)}
                          size="large"
                        />
                      </>
                    )}
                    {stage === 3 && (
                      <>
                        <h4 style={{ marginTop: "5px" }}>Front Nine Scores</h4>
                        <Table
                          style={{ width: "300px" }}
                          size="small"
                          bordered
                          dataSource={tableDataFront}
                          columns={columns}
                          pagination={false}
                        />
                      </>
                    )}
                    {stage === 4 && (
                      <>
                        <h4 style={{ marginTop: "5px" }}>Back Nine Scores</h4>
                        <Table
                          style={{ width: "300px" }}
                          size="small"
                          bordered
                          dataSource={tableDataBack}
                          columns={columns}
                          pagination={false}
                        />
                      </>
                    )}
                    {stage === 5 && (
                      <>
                        <h4 style={{ marginTop: "5px" }}>Download Your Post</h4>
                        <div className="downloadContainer">
                          <Button
                            onClick={() => checkForPass()}
                            style={{ width: "300px" }}
                            type="primary"
                            icon={<DownloadOutlined />}
                            size="large"
                          >
                            Download
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Tool;
