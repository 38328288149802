import React from "react";

function Termsandconditions() {
  return (
    <div className="privacyPage">
      <div className="privacyContent">
        <h2 className="privacy">Terms And Conditions</h2>
        <h4>General information</h4>
        <p className="privacyPara">Last Updated: 04.12.2023</p>
        <p>
          1. Acceptance of Terms{" "}
          <ul>
            <li>
              By using ParPosts by JaysWay LLC ("the App") and related services,
              you agree to comply with and be bound by these Terms and
              Conditions. If you do not agree to these terms, please refrain
              from using the App.
            </li>
          </ul>
        </p>
        <p>
          2. User accounts
          <ul>
            <li>
              {" "}
              Users are not required to create an account to access certain
              features of the App.
            </li>
            <li>
              {" "}
              Users must provide accurate and up-to-date information during any
              purchase interaction.
            </li>
            <li>
              {" "}
              Users are responsible for maintaining the confidentiality of their
              account credentials.
            </li>
          </ul>
        </p>
        <p>
          3. Limited Free Downloads and Paid Membership
          <ul>
            <li>
              Users are entitled to one free download of an image per device.
            </li>
            <li>
              Additional downloads beyond the free limit are available through a
              paid ,embership.
            </li>
            <li>
              Users agree to pay the specified fee for the chosen membership
              plan.
            </li>
          </ul>
        </p>
        <p>
          4. Payment and Refunds
          <ul>
            <li>
              Payment for memberships is processed through Stripe, Inc. Users
              agree to provide accurate billing information.
            </li>
            <li>Payments are non-refundable except where required by law.</li>
          </ul>
        </p>
        <p>
          5. User-Generated Content
          <ul>
            <li>
              Users retain ownership of the images they upload to the App.
            </li>

            <li>
              By uploading images, users grant JaysWay LLC a non-exclusive,
              worldwide, royalty-free license to use, reproduce, and distribute
              the uploaded content for the purpose of providing and improving
              the App.
            </li>
          </ul>
        </p>
        <p>
          6. Prohibited Conduct{" "}
          <ul>
            <li>
              Users must not engage in any illegal or unauthorized activity.{" "}
            </li>
            <li>
              Users must not attempt to circumvent or disable any security
              features of the App.
              <li>
                {" "}
                Users must not upload content that infringes on the rights of
                others, including copyrights, trademarks, or privacy rights.
              </li>
            </li>
          </ul>
        </p>
        <p>
          7. Termination{" "}
          <ul>
            <li>
              JaysWay LLC reserves the right to terminate or suspend user
              accounts for violations of these Terms and Conditions.
            </li>
            <li>
              JaysWay LLC also reserves the right to cease hosting or providing
              the services of the App at any time. In the event of such
              termination, paid users will be provided with advance notice via
              email.
            </li>
          </ul>
        </p>
        <p>
          8. Changes to Terms
          <ul>
            <li>
              JaysWay LLC reserves the right to modify or update these Terms and
              Conditions at any time. Users will be notified of significant
              changes.
            </li>
          </ul>
        </p>
        <p>
          9. Limitation of Liability{" "}
          <ul>
            {" "}
            <li>
              JaysWay LLC is not liable for any direct, indirect, incidental, or
              consequential damages arising from the use of the App.
            </li>
          </ul>
        </p>
        <p>
          10. Governing Law
          <ul>
            <li>
              These Terms and Conditions are governed by the laws of Finland.{" "}
            </li>
          </ul>
        </p>
        <p>
          By using the App, you acknowledge that you have read, understood, and
          agreed to these Terms and Conditions.
        </p>
      </div>
    </div>
  );
}

export default Termsandconditions;
