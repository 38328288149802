import React, { useEffect, useState } from "react";

const CroppedBg = ({
  croppedArea,
  selectedImage,
  gradientLocation,
  colorRgb,
  sliderValues,
  secondGradient,
  stage,
  yourScores,
  yourPars,
  firstName,
  secondName,
  courseName,
  scoreText,
  isSuperMobile,
  scoreLocation,
  divRef
}) => {
  const [score, setScore] = useState("E");
  const scale = 100 / croppedArea.width;
  const transform = {
    x: `${-croppedArea.x * scale}%`,
    y: `${-croppedArea.y * scale}%`,
    scale,
    width: "calc(100% + 0.5px)",
    height: "auto",
  };

  const imageStyle = {
    transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
    width: transform.width,
    height: transform.height,
  };


  useEffect(() => {
    if (!yourScores) return;
    if (!yourPars) return;

    let temp =
      yourScores?.reduce((b, a) => b + a, 0) -
      yourPars?.reduce((b, a) => b + a, 0);
    if (temp === 0) {
      setScore("E");
      return;
    }

    if (temp > 0) {
      temp = "+" + String(temp);
    }

    setScore(temp);
    return;
  }, [yourScores, yourPars]);

  function findNumberClass(i) {
    let dif = yourScores[i] - yourPars[i];
    if (isSuperMobile) {
      if (dif === 1) return "bogeyMobile";
      if (dif === -1) return "birdieMobile";
      if (dif >= 2) return "doubleMobile";
      if (dif <= -2) return "eagleMobile";
      return "parMobile";
    }
    if (dif === 1) return "bogey";
    if (dif === -1) return "birdie";
    if (dif >= 2) return "double";
    if (dif <= -2) return "eagle";
    return "par";
  }

  function calcPositionOfScore(e) {
    let len = String(e).length;
    if (len === 1) {
      return { right: `${isSuperMobile ? 1 : 2}px` };
    }
    if (len >= 3) {
      console.log("hi");
      return { right: `-${isSuperMobile ? 14 : 25}px` };
    }
  }

  function calcLeftNineHole() {
    if (!isSuperMobile) {
      if (scoreLocation === "left") {
        return { left: "55px" };
      } else {
        return { right: "165px" };
      }
    }
    if (scoreLocation === "left") {
      return { left: "31px" };
    } else {
      return { right: "92px" };
    }
  }

  function calcLeftBackNineHole() {
    if (!isSuperMobile) {
      if (scoreLocation === "left") {
        return { left: "165px" };
      } else {
        return { right: "55px" };
      }
    }

    if (scoreLocation === "left") {
      return { left: "92px" };
    } else {
      return { right: "31px" };
    }
  }

  function calcLeftVertical() {
    if (!isSuperMobile) {
      if (scoreLocation === "left") {
        return { left: "125px" };
      } else {
        return { right: "120px" };
      }
    }

    if (scoreLocation === "left") {
      return { left: "71px" };
    } else {
      return { right: "67px" };
    }
  }

  function calcLeftText() {
    if (!isSuperMobile) {
      if (scoreLocation === "right") {
        return { left: "40px" };
      } else {
        return { right: "40px" };
      }
    }
    if (scoreLocation === "right") {
      return { left: "23px" };
    } else {
      return { right: "23px" };
    }
  }

  function calcLeftWatermark() {
    if (scoreLocation === "right") {
      return { left: "0px" };
    } else {
      return { right: "0px" };
    }
  }

  function calcLeftFinal() {
    if (!isSuperMobile) {
      if (scoreLocation === "left") {
        return { left: "48px" };
      } else {
        return { right: "48px" };
      }
    }
    if (scoreLocation === "left") {
      return { left: "26px" };
    } else {
      return { right: "26px" };
    }
  }
  

  return (
    <div>
      <div className={isSuperMobile ? "outputMobile" : "output"} ref={divRef}>
        <img
          className="outputImg"
          src={URL.createObjectURL(selectedImage)}
          alt="theBg"
          style={imageStyle}
        />

        <div
          className={isSuperMobile ? "outputGradientMobile" : "outputGradient"}
          style={
            typeof colorRgb === "string"
              ? {
                  background: `linear-gradient(${gradientLocation}deg, rgba(0,0,0,0) ${
                    100 - sliderValues[1]
                  }%, rgba(0,0,0,${secondGradient / 100}) ${
                    100 - sliderValues[0]
                  }%, rgba(0,0,0,1) 100%)`,
                }
              : {
                  background: `linear-gradient(${gradientLocation}deg, rgba(${
                    colorRgb.toRgb().r
                  },${colorRgb.toRgb().g},${colorRgb.toRgb().b}, 0) ${
                    100 - sliderValues[1]
                  }%, rgba(${colorRgb.toRgb().r},${colorRgb.toRgb().g},${
                    colorRgb.toRgb().b
                  },${secondGradient / 100}) ${100 - sliderValues[0]}%, rgba(${
                    colorRgb.toRgb().r
                  },${colorRgb.toRgb().g},${colorRgb.toRgb().b},1) 100%)`,
                }
          }
        />
        <div
          className={isSuperMobile ? "nameFieldMobile" : "nameField"}
          style={calcLeftText()}
        >
          {firstName}
        </div>
        <div
          className={isSuperMobile ? "nameFieldTwoMobile" : "nameFieldTwo"}
          style={calcLeftText()}
        >
          {secondName}
        </div>
        <div
          className={isSuperMobile ? "extraInfoMobile" : "extraInfo"}
          style={calcLeftText()}
        >
          {courseName}
        </div>
        <div
          className={
            isSuperMobile ? "nineHoleStretchMobile" : "nineHoleStretch"
          }
          style={calcLeftNineHole()}
        >
          {yourScores?.slice(0, 9).map((e, i) => {
            return (
              <div
                key={`${i}front`}
                className={findNumberClass(i)}
                style={
                  isSuperMobile
                    ? {
                        border: "1.5px solid transparent",
                        width: "14.5px",
                        height: "14.5px",
                        textAlign: "center",
                        lineHeight: "13px"
                      }
                    : {
                        border: "2px solid transparent",
                        width: "26px",
                        height: "26px",
                        textAlign: "center",
                        lineHeight: "23px"
                      }
                }
              >
                {e}
              </div>
            );
          })}
          <div
            className={isSuperMobile ? "nineHoleScoreMobile" : "nineHoleScore"}
          >
            {yourScores?.slice(0, 9).reduce((b, a) => b + a, 0)}
          </div>
        </div>
        <div
          className={
            isSuperMobile ? "backNineHoleStretchMobile" : "backNineHoleStretch"
          }
          style={calcLeftBackNineHole()}
        >
          {yourScores?.slice(9).map((e, i) => {
            return (
              <div
                className={findNumberClass(i + 9)}
                style={
                  isSuperMobile
                    ? {
                        border: "1.5px solid transparent",
                        width: "14.5px",
                        height: "14.5px",
                        textAlign: "center",
                        lineHeight: "13px"
                      }
                    : {
                        width: "26px",
                        height: "26px",
                        border: "2px solid transparent",
                        textAlign: "center",
                        lineHeight:"23px"
                      }
                }
                key={`${i}back`}
              >
                {e}
              </div>
            );
          })}
          <div
            className={isSuperMobile ? "nineHoleScoreMobile" : "nineHoleScore"}
          >
            {yourScores?.slice(9).reduce((b, a) => b + a, 0)}
          </div>
        </div>
        <div
          className={isSuperMobile ? "verticalLineMobile" : "verticalLine"}
          style={calcLeftVertical()}
        />
        <div
          className={isSuperMobile ? "finalScoreMobile" : "finalScore"}
          style={calcLeftFinal()}
        >
          {yourScores?.reduce((b, a) => b + a, 0)}
          <div
            className={isSuperMobile ? "extraInfoTwoMobile" : "extraInfoTwo"}
          >
            {scoreText}
          </div>
          <div
            className={
              isSuperMobile ? "finalScoreToParMobile" : "finalScoreToPar"
            }
            style={calcPositionOfScore(score)}
          >
            {score}
          </div>
        </div>
        <div
          className={isSuperMobile ? "parPostsFooterMobile" : "parPostsFooter"}
          style={calcLeftWatermark()}
        >
          Created with www.ParPosts.com
        </div>
      </div>
    </div>
  );
};

export default CroppedBg;
