import React from "react";
import { Collapse } from "antd";

function Faq() {
  return (
    <div className="privacyPage">
        <h1 style={{padding:"20px", textAlign:"center", width: "calc(100% - 40px)"}}>Frequently Asked Questions</h1>
        <Collapse
        style={{width: "90vw", maxWidth:"1000px", backgroundColor:"rgb(245, 245, 245)", marginBottom:"40px"}}
          size="large"
          items={[
            {
              key: "1",
              label: "How do I get started with the app?",
              children: <p>Easy as a tap-in putt! Just upload your favorite golf image, follow the simple steps, tweak a few parameters, and voilà – you'll have a stunning image ready to share with the world. It's like giving your golf memories a hole-in-one treatment.</p>,
            },{
                key: "2",
                label: "What types of images can I upload?",
                children: <p>We're not too picky; we accept both JPEG and PNG files. However, please ensure you have the right to use the images you upload. It's important to respect copyrights and permissions. Whether it's a snapshot of your swing, a scenic course view, or even a selfie with your lucky golf hat, just make sure you have the green light to share it with us!</p>,
              },
              {
                key: "3",
                label: "Can I customize the scorecard on my image?",
                children: <p>Absolutely! Think of it like your own digital caddy. Adjust the scores, add witty comments, or even give yourself a mulligan if needed. The scorecard is your canvas, so paint your golf masterpiece with a touch of your personal style.</p>,
              },
              {
                key: "4",
                label: "Is the app suitable for all skill levels?",
                children: <p>Whether you're a pro or just getting started, our app is your golfing sidekick. From casual rounds to Tour-level games, the app adapts to your needs. No need to worry about a handicap here – we've got your backswing!</p>,
              },
              {
                key: "5",
                label: "Can I share my golf creations on social media?",
                children: <p>Absolutely! Share your golfing triumphs, epic rounds and colorful scorecards with the world. However, please remember that you should have the proper rights to the images you share. Don't tee off on someone else's copyright! Your social media game is about to be as strong as your drives, but let's keep it fair and square.</p>,
              },
              {
                key: "6",
                label: "What if I encounter any issues or have feedback?",
                children: <p>Don't worry, we're not stuck in a sand trap. If you ever encounter issues or have suggestions, reach out to our support team (<strong>jayswayoy@gmail.com</strong>). We're here to assist you, whether it's fixing a bug or just sharing a laugh over your golf anecdotes. Your feedback helps us keep our app in the fairway of excellence!</p>,
              },
          ]}
        />
      </div>
  );
}

export default Faq;
